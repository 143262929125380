import { graphql } from "gatsby"

import { ChapterTitlePage, Epigraph, Scene } from "../components/posts"

const ProseTemplate = ({
  data: { post, note, scenes, chapterSlug },
  pageContext
}) => {
  const isEpigraph = post.scene === "99"
  const isChapter = post.scene === "00"

  return (
    <>
      {isEpigraph ? (
        <Epigraph post={post} pageContext={pageContext} />
      ) : isChapter ? (
        <ChapterTitlePage
          post={post}
          scenes={scenes}
          pageContext={pageContext}
        />
      ) : (
        <Scene
          post={post}
          note={note}
          chapterSlug={chapterSlug}
          pageContext={pageContext}
        />
      )}
    </>
  )
}

export default ProseTemplate

export const proseTemplateQuery = graphql`
  query ProseTemplateQuery($slug: String!, $root: String) {
    post: prosePost(slug: { eq: $slug }) {
      ...SceneFragment
    }
    note: authorNote(post: { eq: $slug }) {
      body
    }
    scenes: allProsePost(
      filter: {
        chapter: { eq: $root }
        published: { eq: true }
        scene: { nin: ["00", "99"] }
      }
      sort: { fields: order, order: ASC }
    ) {
      totalCount
      edges {
        node {
          ...SceneLinksFragment
        }
      }
    }
    chapterSlug: prosePost(
      chapter: { eq: $root }
      published: { eq: true }
      scene: { in: ["00"] }
    ) {
      slug
    }
  }
`
