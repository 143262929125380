import { getImage } from "gatsby-plugin-image"
import { capitalize as cap } from "lodash"

import Seo from "../Seo"
import { Article, Section } from "../containers"
import { ArticleTitle } from "../headings"
import { ChapterPagination } from "../pagination"
import { ChapterSummary } from "../text"
import { SceneLinks } from "../links"

import { useProsePosts } from "../../hooks/use-prose-posts"

const ChapterTitlePage = ({
  post: { volume, chapter, title, summary, card },
  scenes,
  pageContext: { prev, next, slug }
}) => {
  const { allScenes } = useProsePosts()
  const first = allScenes[0].node
  const latest = allScenes[allScenes.length - 1].node

  const seo = {
    image: getImage(card),
    title: `${volume[0]}. ${cap(volume[1])} | ${chapter} · ${title}`
  }

  return (
    <>
      <Seo
        pageTitle={seo.title}
        pageDescription={summary}
        pageUrl={slug}
        image={seo.image}
        pageType="post"
      />
      <Article>
        <ArticleTitle number={chapter} title={title} />
        <Section heading="Chapter Summary" padding={false}>
          <ChapterSummary text={summary} />
        </Section>
        <Section
          heading="List of Links to Individual Chapter Scenes"
          padding={true}
        >
          <SceneLinks scenes={scenes} />
        </Section>
        <Section heading="Story Navigation" sticky={true}>
          <ChapterPagination
            prev={prev ? prev.slug : null}
            next={next ? next.slug : null}
            first={first.slug}
            last={latest.slug}
          />
        </Section>
      </Article>
    </>
  )
}

export default ChapterTitlePage
