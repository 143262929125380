import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Seo from "../Seo"
// import { Comments } from "../comments"
import { Article, Section } from "../containers"
import { TimeStamp, PostTags } from "../blog"
import { PostTitle, SectionTitle } from "../headings"
import { BlogPagination } from "../pagination"
import { Paragraph } from "../text"

const BlogPost = ({
  post: { id, author, title, date, body, summary, tags },
  pageContext
}) => {
  return (
    <>
      <Seo
        pageTitle={title}
        pageDescription={summary}
        pageUrl={pageContext.slug}
        pageType="blog"
      />
      <Article>
        <PostTitle title={title}>
          <TimeStamp date={date} author={author} />
          <PostTags tags={tags} />
        </PostTitle>
        <Section padding={true} semantic={false}>
          <MDXRenderer>{body}</MDXRenderer>
        </Section>
        <Section heading="Blog Post Navigation" sticky={true}>
          <BlogPagination
            prev={pageContext.prev ? pageContext.prev.slug : null}
            next={pageContext.next ? pageContext.next.slug : null}
          />
        </Section>
        <Section padding={true}>
          <SectionTitle title="Comments" />
          <Paragraph>Apologies — comments are temporarily closed. They will re-open as soon as I find a stable or self-hosted solution for static-site comment systems.</Paragraph>
          <Paragraph>In the meantime, you may comment on the <a href="https://www.scribblehub.com/series/377889/the-bitter-drop/" target="_blank" rel="noopener noreferrer" sx={{variant: "links.body"}}>ScribbleHub</a> mirror or the <a href="https://forums.sufficientvelocity.com/threads/the-bitter-drop-gothic-fantasy-romance.110182/" target="_blank" rel="noopener noreferrer" sx={{variant: "links.body"}}>SufficientVelocity</a> mirror; if you're commenting on a blog post, rather than a story update, please kindly link the post in question so others (including me) have some idea of what you're responding to.</Paragraph>
        </Section>
      </Article>
    </>
  )
}

export default BlogPost

export const blogPostQuery = graphql`
  fragment BlogPostFragment on BlogPost {
    id
    author
    title
    date
    slug
    body
    summary
    tags
  }
`
