import { MDXRenderer } from "gatsby-plugin-mdx"
import { getImage } from "gatsby-plugin-image"
import { capitalize as cap } from "lodash"

import Seo from "../Seo"
import { Article, Section } from "../containers"
import { ArticleTitle } from "../headings"
import { ChapterPagination } from "../pagination"

import { useProsePosts } from "../../hooks/use-prose-posts"

const Epigraph = ({
  post: { volume, chapter, title, body, summary, card },
  pageContext: { prev, next, slug }
}) => {
  const { allScenes } = useProsePosts()
  const first = allScenes[0].node
  const latest = allScenes[allScenes.length - 1].node

  const seo = {
    image: getImage(card),
    title: `${volume[0]}. ${cap(volume[1])} | ${cap(chapter)}`
  }

  return (
    <>
      <Seo
        pageTitle={seo.title}
        pageDescription={summary}
        pageUrl={slug}
        image={seo.image}
        pageType="post"
      />
      <Article>
        <ArticleTitle number={volume[0]} title={title} epigraph={true} />
        <Section padding={true} semantic={false}>
          <MDXRenderer>{body}</MDXRenderer>
        </Section>
        <Section heading="Story Navigation" sticky={true}>
          <ChapterPagination
            prev={prev ? prev.slug : null}
            next={next ? next.slug : null}
            first={first.slug}
            last={latest.slug}
          />
        </Section>
      </Article>
    </>
  )
}

export default Epigraph
