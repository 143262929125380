import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { getImage } from "gatsby-plugin-image"
import { capitalize as cap } from "lodash"

import Seo from "../Seo"
// import { Comments } from "../comments"
import { Article, Section } from "../containers"
import { SceneTitle, SectionTitle } from "../headings"
import { ScenePagination } from "../pagination"
import { Paragraph } from "../text"

import { useProsePosts } from "../../hooks/use-prose-posts"

const Scene = ({
  post: { id, volume, chapter, scene, title, body, summary, card, has_note },
  note,
  chapterSlug: root,
  pageContext
}) => {
  const { allScenes } = useProsePosts()
  const first = allScenes[0].node
  const latest = allScenes[allScenes.length - 1].node
  const volumeTitle = `${volume[0]}. ${cap(volume[1])}`
  // const commentId = pageContext.slug === "/story/sparrows/000/01/" ? pageContext.slug : id

  const seo = {
    image: getImage(card),
    title: `${volumeTitle} | ${chapter} · ${title}, scene ${scene}`
  }

  return (
    <>
      <Seo
        pageTitle={seo.title}
        pageDescription={summary}
        pageUrl={pageContext.slug}
        image={seo.image}
        pageType="post"
      />
      <Article>
        <SceneTitle
          volume={volumeTitle}
          chapter={chapter}
          scene={scene}
          title={title}
        />
        <Section padding={true} semantic={false}>
          <MDXRenderer>{body}</MDXRenderer>
        </Section>

        <Section heading="Story Navigation" sticky={true}>
          <ScenePagination
            chapter={root.slug}
            prev={pageContext.prev ? pageContext.prev.slug : null}
            next={pageContext.next ? pageContext.next.slug : null}
            first={first.slug}
            last={latest.slug}
          />
        </Section>
        {has_note && (
          <Section padding={true}>
            <SectionTitle title="Author’s Note" />
            <MDXRenderer>{note.body}</MDXRenderer>
          </Section>
        )}
        <Section padding={true}>
          <SectionTitle title="Comments" />
          <Paragraph>Apologies — comments are temporarily closed. They will re-open as soon as I find a stable or self-hosted solution for static-site comment systems.</Paragraph>
          <Paragraph>In the meantime, you may comment on the <a href="https://www.scribblehub.com/series/377889/the-bitter-drop/" target="_blank" rel="noopener noreferrer" sx={{variant: "links.body"}}>ScribbleHub</a> mirror or the <a href="https://forums.sufficientvelocity.com/threads/the-bitter-drop-gothic-fantasy-romance.110182/" target="_blank" rel="noopener noreferrer" sx={{variant: "links.body"}}>SufficientVelocity</a> mirror.</Paragraph>
        </Section>
      </Article>
    </>
  )
}

export default Scene

export const sceneQuery = graphql`
  fragment SceneFragment on ProsePost {
    id
    volume
    chapter
    summary
    scene
    title
    date
    body
    has_note
    card {
      childImageSharp {
        gatsbyImageData(formats: PNG, quality: 100)
      }
    }
  }
`
//<Comments pageContext={pageContext} />
